/* ----- Global Variables ----- */
:root {
  --black : #000000;
  --white : #ffffff;
  --lightgrey : #e0e0e0;
  --blue : #065dd8;
  --red : #ff0000;
}

/* ----- Selectors by element ----- */

body {
  display: flex;
  flex-direction: column;
  margin-bottom: 4em;
  padding-left: 8em;
  padding-right: 8em;

  font-family:"Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: small;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

footer {
  width: 100%;
  background-color: var(--black);
  color: var(--white);
  position: fixed;
  bottom: 0;
  left: 0;
  border-top-right-radius: 40px;
}

footer > p {
  margin-left: 1em;
}

/* ----- Selectors by class ----- */

.advomodule-root {
  font-weight: bold;
  padding-left: 1em;
  background-color: var(--lightgrey);
}

.module-price {
  text-align: end;
  width: 76px;
}

/* ----- Selectors by id ----- */
/* top-level div including customer data, license data, license terms and submit button */
#complete-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#complete-form table, th, td {
  border: 0px solid var(--lightgrey);
  border-collapse: collapse;
  border-radius: 5px;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: 0;
}

#complete-form th, td{
  padding: 0.6em;
}

#complete-form button {
  background-color: var(--white);
  border-radius: 4px;
  border: 2px solid var(--blue);
  box-sizing: border-box;
  color: var(--black);
  cursor: pointer;
  display: block;
  font-size: larger;
  margin-top: 0.4em;
  padding: 0.7em 1.3em;
  text-align: center;
  transition: all .2s cubic-bezier(.22, .61, .36, 1);
  width: 18.3em;
}

#complete-form button:hover {
  background-color: var(--lightgrey);
  scale: 1.02;
}

#complete-form button:disabled {
  background-color: var(--lightgrey);
  color: var(--grey);
  cursor: not-allowed;
  scale: 1;
}


/* top-level div including customer data, license data, license terms and submit button */
#customer-license-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8em;
  margin-left: 0.6em;
  margin-bottom: 1em;
}

/* div holding customer data input fields */
#customer-data {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  flex: 1 1 350px;
  margin-left: 1em;
  margin-right: 1em;
  text-align: left;
}

#customer-data > h2 {
  flex: 100%;
}

/* label and input fields for customer data*/
#customer-data div {
  display: grid;
  flex: 50% 0;
  justify-self: flex-start;
}

#customer-data input {
  display: block;
  margin-left: 0.2em;
  margin-right: 0.2em;
  margin-bottom: 1em;
}

#customer-data label {
  margin-left: 0.2em;
  margin-right: 0.2em;
}

#customer-data input:focus {
  outline: none;
  border-bottom: 1px dotted;
}

/* div holding license selection table and cost*/
#form-licenses {
  flex: 1 1 400px;
  margin-left: 1em;
  margin-right: 1em;
}

/* table with advomodules, name, description, price and selection-checkbox */
#table-advomodules {
  max-height: 400px;
  text-align: left;
}

#table-advomodules th:last-child {
  text-align: right;
}

/* table with final prices for selected modules. => ModulePrices * NumberOfLocations */

#table-prices {
  border-top: 1px solid var(--black);
}

#table-prices  th{
  text-align: start;
}

#table-prices  td{
  width: 76px;
  text-align: end;
}

#buttons-and-accept {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
}

#license-terms-container {
  margin-left: 1em;
  margin-right: 1em;
}

/* div holding license terms - content gets filled by license_terms pulled from server. Meant to display html */
#license-terms {
  overflow: auto;
  height: 400px; 
  text-align: left;
  resize: vertical;
  margin-bottom: 2em;
  border: 1px solid var(--lightgrey);
  border-radius: 4px;
  padding: 1em;
}

/* label for checkbox to accept license terms, needs some different style properties from other labels */
#accept-license-terms-div {
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
}

#accept-license-terms-label {
  display: block;
  width: fit-content;
  margin-top: 1em;
  margin-bottom: 0.4m;
  font-size: medium;
}

/* small advoservice logo in the footer */
#footer-advoservice-logo {
  max-width: 130px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  right: 30px;
  margin-top: 0.6em;
}

#customer-license-form-submit-button {
  color: var(--white);
  background-color: var(--blue);
}

#customer-license-form-submit-button:disabled {
  background-color: var(--lightgrey);
  color: var(--grey);
}

#logout-button {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: smaller;
  width: fit-content;
  background-color: var(--black);
  color: var(--white); 
  border: none;
}

/* Login screen */
#login-screen {
  border: 1px solid var(--lightgrey);
  background-color: var(--lightgrey);
  border-radius: 5px;
  text-align: center;
  padding-bottom: 2em;
  max-width: 30em;
  margin-left: auto;
  margin-right: auto;
}

#login-screen button {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}

#login-screen button:hover {
  background-color: var(--white);
  scale: 1.02;
}

/* ----- Classes set from code ----- */
/* Input and labels: border and text-color depending on validity */
.label-state-valid {
  color: var(--black);
}

.label-state-invalid {
  color: var(--red);
}

.label-state-disabled {
  color: var(--grey);
}

.input-state-valid {
  border: none;
  border-bottom: 1px solid var(--lightgrey);
}

.input-state-invalid {
  border: none;
  border-bottom: 1px solid var(--red);
}

.input-state-disabled {
  border: none;
  border-bottom: 1px solid var(--lightgrey);
  color: var(--grey);
}

/* ----- Media queries for smaller screen widths ----- */
@media (max-width: 840px){
  body {
    padding-left: 2em;
    padding-right: 2em;
  }
}

@media (max-width: 690px){
  #buttons-and-accept {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 470px){
  body {
    padding-left: 0em;
    padding-right: 0em;
  }

  #customer-data div {
    flex: 100% 0;
  }
}

/* ----- print-view specific css ----- */
@media print {
  footer {
    display: none;
  }

  body, input, th, td, p, label {
    font-size: small;
  }

  .Toastify {
    display: none;
  }

  #accept-license-terms-label, button {
    display: none;
  }

  #license-terms-container {
    page-break-before: always;
  }

  #license-terms {
    height: auto;
  }
}